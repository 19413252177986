import React from "react";
import styled from "@emotion/styled";

const PopupMapInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
`;

export default PopupMapInfo;
